/* eslint-disable react-hooks/exhaustive-deps */
// abstract route to capture authtoken and refreshToken from backend
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { parseQueryString } from 'Services/Util';

// Hooks
import useAppReducer from 'Hooks/useAppReducer';

export default function Auth() {
  const [, dispatch] = useAppReducer();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const p = parseQueryString(location.search);
    const { accessToken, refreshToken } = p;
    if (accessToken && refreshToken) {
      dispatch({
        type: 'TOKEN:SET',
        accessToken,
        refreshToken,
        loggedIn: true,
      });
      // if entryLocation existed in sessionStorage, try to go to that location instead of default
      try {
        const entryLocationString =
          window.sessionStorage.getItem('entryLocation');
        window.sessionStorage.removeItem('entryLocation');
        if (entryLocationString) {
          navigate(JSON.parse(entryLocationString));
        }
      } catch (err) {}
    } else {
      navigate('/chains', { replace: true });
    }
  }, []);

  return <div />;
}
