/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect } from 'react';
import { Badge, Button } from 'disco-ui';

import { EditorView, basicSetup } from 'codemirror';
import { EditorState } from '@codemirror/state';
import { javascript } from '@codemirror/lang-javascript';

export function beautify(input = '') {
  try {
    return JSON.stringify(JSON.parse(input), null, 2);
  } catch (err) {
    return '';
  }
}

export default function JsonCodeMirror({
  id,
  defaultValue,
  lineNumbers = true,
  onChange,
  setToast,
  readOnly,
  ...restProps
}) {
  const node = useRef(null);
  const view = useRef(null);

  const beautified = beautify(defaultValue);

  useEffect(() => {
    const state = EditorState.create({
      doc: beautified ? beautified : defaultValue,
      extensions: [
        basicSetup,
        javascript(),
        EditorView.updateListener.of(function (e) {
          onChange(e.state.doc.toString());
        }),
        EditorView.contentAttributes.of({ contenteditable: !readOnly }),
      ],
      tabSize: 2,
      lineNumbers,
      ...restProps,
    });

    view.current = new EditorView({ state, parent: node.current });

    return () => {
      view.current.destroy();
    };
  }, [defaultValue]);

  function onBeautify() {
    if (view.current) {
      let v = beautify(view.current.state.doc.toString());
      if (v) {
        view.current.dispatch({
          changes: { from: 0, to: view.current.state.doc.length, insert: v },
        });
      } else {
        if (setToast) {
          setToast({
            type: 'error',
            content: (
              <div>
                JSON syntax error.
                <br /> Please check the configuration syntax!
              </div>
            ),
          });
        }
      }
    }
  }

  return (
    <div className="JsonCodeMirror relative">
      {readOnly ? (
        <Badge
          className="beautify cursor-pointer absolute top-[12px] right-[24px] z-[100]"
          color="white"
        >
          READ ONLY
        </Badge>
      ) : (
        <Button
          color="white"
          size="small"
          className="beautify absolute top-[12px] right-[24px] z-[100]"
          onClick={onBeautify}
        >
          Beautify
        </Button>
      )}
      <div className="editor" ref={node} />
    </div>
  );
}
