import { Fragment, useMemo, useEffect } from 'react';
import map from 'lodash/map';

// Relay
import graphql from 'babel-plugin-relay/macro';
import createQueryRenderer from 'Relay/createQueryRenderer';

import EmptyItem from '../EmptyItem';
import ListItem from '../ListItem';

const QUEUE_LABEL_MAP = {
  DATA_OPS_REVIEW: 'Review',
  DATA_OPS_CONFIGURATION: 'Configuration',
  FIELD: 'Assigned to Field',
  ENGINEERING: 'Assigned to Engineer',
};

function reduceTasksToQueues(acc, { node }) {
  const { queue, store, completion, ...restProps } = node || {};

  const { dbId: storeId, name: storeName, storeChain } = store || {};

  const { dbId: chainId } = storeChain || {};

  if (acc[queue] && storeId && chainId && completion === 'INCOMPLETE') {
    acc[queue].push({
      queue,
      storeName,
      storeId,
      chainId,
      completion,
      ...restProps,
    });
  }

  return acc;
}

function DataopsSidebar({
  viewer,
  queryParams,
  selectTask,
  nextTaskInQueue,
  setNextTaskInQueue,
}) {
  const { configurationTasks } = viewer || {};

  let QUEUES = useMemo(
    () =>
      configurationTasks.edges.reduce(reduceTasksToQueues, {
        DATA_OPS_REVIEW: [],
        DATA_OPS_CONFIGURATION: [],
        FIELD: [],
        ENGINEERING: [],
      }),
    [configurationTasks]
  );

  useEffect(() => {
    if (nextTaskInQueue) {
      let dbId;
      if (QUEUES[nextTaskInQueue].length) {
        dbId = QUEUES[nextTaskInQueue][0].dbId;
      } else if (QUEUES['DATA_OPS_REVIEW'].length) {
        dbId = QUEUES['DATA_OPS_REVIEW'][0].dbId;
      } else if (QUEUES['DATA_OPS_CONFIGURATION'].length) {
        dbId = QUEUES['DATA_OPS_CONFIGURATION'][0].dbId;
      }
      selectTask(dbId);
      setNextTaskInQueue(null);
    }
  }, [nextTaskInQueue, QUEUES, selectTask, setNextTaskInQueue]);

  return (
    <div className="ReportConfigEditorSidebar">
      {!nextTaskInQueue &&
        map(QUEUES, (queue, key) => {
          return (
            <Fragment key={key}>
              <div className="ReportConfigEditorSidebar--divider px-3 py-2">
                <div className="text-[0.53rem] uppercase left">
                  {QUEUE_LABEL_MAP[key]}
                </div>
              </div>
              {queue.length ? (
                queue.map((task) => (
                  <ListItem
                    key={task.id}
                    {...task}
                    selectTask={selectTask}
                    queryParams={queryParams}
                  />
                ))
              ) : (
                <EmptyItem />
              )}
            </Fragment>
          );
        })}
    </div>
  );
}

export default createQueryRenderer(
  DataopsSidebar,
  graphql`
    query DataopsSidebarQuery {
      viewer {
        configurationTasks(
          first: 300
          filters: { assignedToMe: true, completed: INCOMPLETE }
        ) {
          edges {
            node {
              id
              dbId
              queue
              completion
              store {
                name
                dbId
                storeChain {
                  dbId
                }
              }
              exception {
                queue
              }
              createdAt
              updatedAt
              updatedBy {
                email
              }
            }
          }
        }
      }
    }
  `
);
