import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';

// styles
import 'react-virtualized/styles.css';
import 'react-vis/dist/style.css';

import 'disco-ui/dist/style.css';
import 'Styles/react-contextmenu.css';
import 'Styles/app.css';
import 'Styles/index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// RootComponent
import App from './app';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
serviceWorker.unregister();
