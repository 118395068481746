import { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const NavContext = createContext();

// for Provider
export function useNav(props) {
  const [backAction, setBackAction] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setBackAction(null);
  }, [location.pathname]);

  return { backAction, setBackAction };
}

// for Consumer
export default function useNavContext() {
  return useContext(NavContext);
}
