import cn from 'classnames';

const TAB_LABELS = [
  { label: 'DATA OPS | REVIEW', tab: 'data_ops_review' },
  { label: 'DATA OPS | CONFIGURE', tab: 'data_ops_configuration' },
  { label: 'FIELD', tab: 'field' },
  { label: 'ENGINEER', tab: 'engineering' },
  { label: 'USER', tab: 'user' },
];

export default function ReportConfigQueuesTabs({
  tab: currentTab,
  switchTab,
  me,
}) {
  const tabs =
    me && me.adminRole === 'DATA_OPERATIONS_MANAGER'
      ? TAB_LABELS
      : TAB_LABELS.filter((t) => t.label !== 'USER');

  return (
    <div className="ReportConfigQueuesTabs">
      {tabs.map(({ label, tab }) => (
        <div
          key={tab}
          className={cn(
            'ReportConfigQueuesTabs--tab p-3   uppercase',
            tab === currentTab && 'selected'
          )}
          onClick={() => {
            switchTab(tab);
          }}
        >
          {label}
        </div>
      ))}
    </div>
  );
}
