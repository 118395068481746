import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';

import environment from 'Relay/environment';

const mutation = graphql`
  mutation CompleteConfigurationTaskMutation(
    $input: CompleteConfigurationTaskInput!
  ) {
    completeConfigurationTask(input: $input) {
      configurationTask {
        id
        completion
      }
    }
  }
`;

function CompleteConfigurationTask(input, onCompleted, onError) {
  const variables = {
    input
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted,
    onError
  });
}

export default CompleteConfigurationTask;
