import { useEffect } from 'react';

// relay
import { RelayEnvironmentProvider } from 'react-relay';
import Environment from 'Relay/environment';

// router
import { BrowserRouter } from 'react-router-dom';

// Components
import ScrollToTop from 'Components/ScrollToTop';
import Routes from 'Routes';

// hooks
import { AppReducerContext, useAppReducer } from 'Hooks/useAppReducer';
import { BreakpointsContext, useBreakpoints } from 'Hooks/useBreakpoints';

// skucli
import skucli from 'Services/skucli';

// Hackish way to expose appReducer for Relay/env and Services/WebApi
let appReducer;
export function getAppReducer() {
  return appReducer;
}

function App() {
  appReducer = useAppReducer();
  const bp = useBreakpoints();

  useEffect(() => {
    window.skucli = skucli({
      getAppReducer,
    });
    return () => {
      window.skucli = null;
    };
  });

  return (
    <RelayEnvironmentProvider environment={Environment}>
      <AppReducerContext.Provider value={appReducer}>
        <BreakpointsContext.Provider value={bp}>
          <BrowserRouter>
            <ScrollToTop>
              <Routes />
            </ScrollToTop>
          </BrowserRouter>
        </BreakpointsContext.Provider>
      </AppReducerContext.Provider>
    </RelayEnvironmentProvider>
  );
}

export default App;
