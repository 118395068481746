import { DateTime } from 'luxon';

import { webapi } from 'Config/Hosts';

// Redux
import useAppReducer from 'Hooks/useAppReducer';

// Components
import { Alert, Card } from 'disco-ui';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';

export default function ReportConfigEditorPreview({
  previewReport,
  closePreview,
}) {
  const [appState] = useAppReducer();
  const { accessToken } = appState.token || {};

  const dbId = previewReport ? previewReport.dbId : '';
  const fileUrl = `${webapi}/open_report/${dbId}?token=${accessToken}`;

  const reportDate = previewReport
    ? DateTime.fromSeconds(previewReport.beginAt).toUTC().toFormat('yyyy-MM-dd')
    : '';

  return (
    <div className="ReportConfigEditorPreview p-3">
      <Card className="ReportConfigEditorPreview--content p-3">
        <div className="flex justify-between">
          <h4 className="pb-3">Report Date: {reportDate}</h4>
          <CloseIcon
            className="clickable"
            width={20}
            height={20}
            onClick={closePreview}
          />
        </div>
        {dbId ? (
          <iframe
            className="border-0 w-full h-[calc(100vh - 380px)] float-left"
            title="report"
            src={fileUrl}
          ></iframe>
        ) : (
          <div className="p-5">
            <Alert color="warning" icon={<ErrorOutlineIcon />}>
              No Preview Report.
            </Alert>
          </div>
        )}
      </Card>
    </div>
  );
}
