import { useState, useMemo, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  getNodeID,
  parseQueryString,
  serializeQueryString,
} from 'Services/Util';
import ConfigLocalStorage from 'Services/ConfigLocalStorage';

import UpdateConfigurationTaskQueue from 'Mutations/UpdateConfigurationTaskQueue';
import CompleteConfigurationTask from 'Mutations/CompleteConfigurationTask';

// Components
import { Toast } from 'disco-ui';
import DataopsSidebar from './DataopsSidebar';
import EngineerSidebar from './EngineerSidebar';
import ReportConfigEditorContent from './ReportConfigEditorContent';

import './style.scss';

function ReportConfigEditor({ viewer }) {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = parseQueryString(location.search);
  const selectedNodeId = queryParams.dbId
    ? getNodeID('ConfigurationTask', queryParams.dbId)
    : null;
  const selectedDbId = queryParams.dbId ? queryParams.dbId : null;

  const { me } = viewer || {};
  const { adminRole } = me || {};

  const [refetching, setRefetching] = useState(false);
  const [nextTaskInQueue, setNextTaskInQueue] = useState(null);
  const [completing, setCompleting] = useState(null); // {configurationTaskId, configuration, prevQueue}
  const [assigning, setAssigning] = useState(null); // {configurationTaskId, queue, prevQueue}

  // Toast
  const [toast, setToast] = useState(null);
  // memorized to prevent over render
  const memoToast = useMemo(() => {
    return toast ? (
      <Toast
        content=""
        type="default"
        {...toast} // content and type
        onClose={() => setToast(null)}
        position="top-center"
      />
    ) : null;
  }, [toast]);

  const selectTask = (dbId) => {
    const search = serializeQueryString({
      ...queryParams,
      dbId,
    });
    navigate({ search });
  };

  // Refetching sidebar hack
  // Instead of using RefectchContainer, we have to unmount and mount sidebar again
  // RefetchContainer won't update entire connection
  // Ref: https://github.com/facebook/relay/issues/2244
  useEffect(() => {
    if (refetching) {
      setRefetching(false);
    }
  }, [refetching]);

  // Assign to other queue
  function assignTo(configurationTaskId, queue, prevQueue) {
    setAssigning({
      configurationTaskId,
      queue,
      prevQueue,
    });
  }

  useEffect(() => {
    if (assigning) {
      const { configurationTaskId, queue, prevQueue } = assigning;

      const input = {
        configurationTaskId,
        queue,
      };

      UpdateConfigurationTaskQueue(
        input,
        () => {
          setAssigning(null);
          setRefetching(true);
          setNextTaskInQueue(prevQueue);
        },
        (err) => {
          console.log(err);
          setToast({
            type: 'error',
            content: 'Assign task failed.',
          });
          setAssigning(null);
        }
      );
    }
  }, [assigning]);

  // Complete the task
  function completeTask(configurationTaskId, configuration, prevQueue) {
    setCompleting({ configurationTaskId, configuration, prevQueue });
  }

  useEffect(() => {
    if (completing) {
      const { configurationTaskId, configuration, prevQueue } = completing;

      const input = {
        configurationTaskId,
        configuration,
      };
      CompleteConfigurationTask(
        input,
        () => {
          setCompleting(false);
          setRefetching(true);
          setNextTaskInQueue(prevQueue);
          ConfigLocalStorage.remove(configurationTaskId);
        },
        (err) => {
          console.log(err);
          setToast({
            type: 'error',
            content: 'Cannot complete the task.',
          });
          setCompleting(false);
        }
      );
    }
  }, [completing]);

  useEffect(() => {
    if (!/DATA|ENGINEER/i.test(adminRole)) {
      console.log('IIIINNN!!');
      const search = serializeQueryString({
        ...queryParams,
        dbId: selectedDbId,
      });
      navigate({ to: 'report_config_queues', search });
    }
  }, [adminRole]);

  const Sidebar = adminRole === 'ENGINEER' ? EngineerSidebar : DataopsSidebar;
  return (
    <div
      className="ReportConfigEditor"
      data-testid="ReportConfigEditor__container"
    >
      <div className="ReportConfigEditorToolbar flex items-center justify-between px-3">
        <h3 className="pr-3">Report Configuration</h3>
      </div>
      <div className="ReportConfigEditor--main flex align-stretch">
        {refetching ? (
          <div className="ReportConfigEditorSidebar" />
        ) : (
          <Sidebar
            queryParams={queryParams}
            selectTask={selectTask}
            nextTaskInQueue={nextTaskInQueue}
            setNextTaskInQueue={setNextTaskInQueue}
          />
        )}
        {selectedNodeId && (
          <ReportConfigEditorContent
            id={selectedNodeId}
            dbId={selectedDbId}
            assignTo={assignTo}
            assigning={assigning}
            completeTask={completeTask}
            completing={completing}
            setToast={setToast}
          />
        )}
      </div>
      {memoToast}
    </div>
  );
}

export { ReportConfigEditor };
