import graphql from 'babel-plugin-relay/macro';
import createQueryRenderer from 'Relay/createQueryRenderer';

import { Modal, Button } from 'disco-ui';
import RecentEngageTransaction from 'Components/RecentEngageTransaction';
import ConfigurationTaskComments from 'Components/ConfigurationTaskComments';
import UpdateConfigurationTaskQueue from 'Mutations/UpdateConfigurationTaskQueue';

function CommentsModal({ viewer, setTaskId }) {
  const { configurationTask } = viewer;
  const { dbId: configurationTaskId, store } = configurationTask;
  const { dbId: storeId, name: storeName, storeChain } = store || {};
  const { dbId: chainId } = storeChain || {};

  function onRequestClose(e) {
    if ((e && /ReactModal__Overlay/.test(e.target.className)) || !setTaskId)
      return; // prevent dismiss from overlay
    setTaskId(null);
  }

  function assignTo(queue) {
    const input = {
      configurationTaskId,
      queue,
    };

    UpdateConfigurationTaskQueue(
      input,
      (res) => {
        setTaskId(null);
      },
      (err) => {}
    );
  }

  return (
    <Modal
      className="ReportConfigQueuesCommentsModal"
      title={
        <div className="flex items-center">
          <div>{storeName}</div>
          <div className="text-[0.53rem] ml-3">
            <div>Chain ID: {chainId}</div>
            <div>Store ID: {storeId}</div>
          </div>
        </div>
      }
      width="60vw"
      ariaHideApp={false}
      dismissable={true}
      onRequestClose={onRequestClose}
      isOpen={true}
      footer={
        <div>
          <Button
            color="white"
            onClick={() => {
              assignTo('DATA_OPS_CONFIGURATION');
            }}
          >
            Assign to Data Ops
          </Button>
        </div>
      }
    >
      <RecentEngageTransaction storeId={storeId} />
      <ConfigurationTaskComments configurationTask={configurationTask} />
    </Modal>
  );
}

export default createQueryRenderer(
  CommentsModal,
  graphql`
    query CommentsModalQuery($id: ID!) {
      viewer {
        configurationTask(id: $id) {
          dbId
          store {
            dbId
            name
            storeChain {
              dbId
            }
          }
          ...ConfigurationTaskComments_configurationTask
        }
      }
    }
  `,
  ({ id }) => {
    return {
      id,
    };
  }
);
