import REPORT_PERIODS from 'Constants/REPORT_PERIODS';
import UseFixture from 'Config/UseFixture';
import reduce from 'lodash/reduce';
import isArray from 'lodash/isArray';
import filter from 'lodash/filter';
import { DateTime } from 'luxon';

export function getUrlfromEmail(email) {
  if (typeof email !== 'string') return null;
  const domain = email.split('@')[1];
  if (/gmail.com/i.test(domain)) {
    // gmail
    return 'https://mail.google.com';
  } else if (/yahoo.(com|co)/i.test(domain)) {
    // yahoo
    return 'https://mail.yahoo.com/';
  } else if (/(hotmail|live|msn|passport|outlook).(com|co)/i.test(domain)) {
    // ms
    return 'https://mail.live.com/';
  } else if (/(icloud|me).com/i.test(domain)) {
    // icloud
    return 'https://www.icloud.com/mail';
  } else if (/aol.com/i.test(domain)) {
    // aol
    return 'https://mail.aol.com';
  } else if (/(att|sbcglobal).net/i.test(domain)) {
    // att
    return 'https://www.att.net';
  } else if (/comcast.(com|net)/i.test(domain)) {
    // comcast
    return 'https://login.comcast.net/';
  } else {
    return null;
  }
}

export function getNodeID(type, dbId) {
  return UseFixture ? `${type}:${dbId}` : window.btoa(`${type}-${dbId}`);
}

export function parseQueryString(raw) {
  if (!raw) return {};
  return raw
    .replace('?', '')
    .split('&')
    .reduce((acc, p) => {
      let [key, value] = decodeURIComponent(p).split('=');
      // turn boolean to correct value
      if (/^true$|^false$/.test(value)) {
        value = value === 'true';
      }
      // deal with array
      if (/(\w+)\[\]$/.test(key)) {
        key = key.match(/(\w+)\[\]$/)[1];
        if (!isArray(acc[key])) acc[key] = [];
        acc[key].push(value);
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});
}

export function serializeQueryString(q) {
  if (!q) return '';
  return (
    '?' +
    reduce(
      q,
      (acc, value, key) => {
        if (
          (!!value || value === 0 || typeof value === 'boolean') &&
          value.toString()
        ) {
          if (isArray(value)) {
            value.forEach((v) =>
              acc.push(
                `${encodeURIComponent(key + '[]')}=${encodeURIComponent(v)}`
              )
            );
          } else {
            acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
          }
        }
        return acc;
      },
      []
    ).join('&')
  );
}

export function parseFilters(queryString) {
  const { orderBy = [], ...restFilters } = parseQueryString(queryString);
  return {
    ...restFilters,
    orderBy: orderBy.reduce((acc, ordering) => {
      let match = ordering.match(/(\w+)_(ASC|DESC)/i);
      if (match) {
        acc.push({
          sort: match[1].toUpperCase(),
          direction: match[2].toUpperCase(),
        });
      }
      return acc;
    }, []),
  };
}

export function removeKeysFromObject(obj, keysToRemove) {
  const objCopy = { ...obj };
  keysToRemove.forEach((k) => delete objCopy[k]);
  return objCopy;
}

export function getValidFilters(filters) {
  return Object.values(filters).filter((value) => {
    if (typeof value === 'string') {
      return value.length > 0;
    }
    if (value instanceof Array) {
      return value.length > 0;
    }
    if (typeof value === 'object') {
      return Object.keys(value).length > 0;
    }

    return value;
  });
}

export function serializeFilters(filters) {
  const { orderBy, ...restFilters } = filters;

  return serializeQueryString({
    ...restFilters,
    orderBy: orderBy
      ? orderBy.map(({ sort, direction }) => `${sort}_${direction}`)
      : [],
  });
}

export function cleanInput(input) {
  return filter(
    input.toString().split(/[ ,]+/),
    (id) => !isNaN(id) && id !== ''
  );
}

export function cleanAlphaNumericInput(input) {
  let array = input.toString().split(/[ ,]+/);
  return filter(array, (value) => Boolean(value.match(/^[A-Za-z0-9]*$/)));
}

// https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
// random string up to 10 chars
export function randomString(len) {
  if (len > 10 || len < 1) throw Error('Length has to be 1~10');
  return Math.random().toString(36).substr(2, len);
}

// reduce dates to Range presentation if they are consecutive
// ["2018-08-31","2018-09-01","2018-09-03","2018-09-05","2018-09-06"]
// => ["2018-08-31:2018-09-01", "2018-09-03", "2018-09-05:2018-09-06"]
const DATE_FORMAT = 'yyyy-MM-dd';
export function reduceDateRange(dates) {
  let start = null;
  let end = null;

  const result = dates.sort().reduce((acc, d) => {
    const dt = DateTime.fromFormat(d, DATE_FORMAT);

    if (start === null) {
      start = dt;
    } else if (+dt.minus({ days: 1 }) !== +end) {
      // not consecutive date dt-1 !== prev end
      if (start === end) {
        // solitary date
        acc.push(start.toFormat(DATE_FORMAT));
      } else {
        // date range
        acc.push(`${start.toFormat(DATE_FORMAT)}:${end.toFormat(DATE_FORMAT)}`);
      }
      start = dt;
    }
    end = dt;

    return acc;
  }, []);
  // do one more check for the last date
  if (start === end) {
    result.push(start.toFormat(DATE_FORMAT));
  } else {
    result.push(`${start.toFormat(DATE_FORMAT)}:${end.toFormat(DATE_FORMAT)}`);
  }

  return result;
}

export function checkFlexWorkforce(username) {
  // return /^s\./i.test(username);
  return false; // always return false for now
}

function checkList(username, list) {
  const user = username.substring(0, username.indexOf('@'));
  const usersList = process.env[list] || '';

  return usersList.split(',').indexOf(user) !== -1;
}

export function checkCloudSyncWhitelist(username) {
  return checkList(username, 'REACT_APP_CLOUDSYNC_WHITELIST');
}

export function checkSyncAdminWhitelist(username) {
  return checkList(username, 'REACT_APP_SYNC_ADMIN_WHITELIST');
}

export function checkCustomerSupportBlacklist(username) {
  return checkList(username, 'REACT_APP_CUSTOMER_SUPPORT_BLACKLIST');
}

export function getDirectionMap(orderBy) {
  return reduce(
    orderBy,
    (acc, { sort, direction }) => {
      acc[sort] = direction;
      return acc;
    },
    {}
  );
}

// Generate an object containing all report periods and quarters up to the current day
// for a given report type.
export function generateReportPeriods(reportType) {
  let weekEndingDates = [REPORT_PERIODS[reportType.toUpperCase()]];

  let quarters = [];
  let nextWeekEndingDate = DateTime.fromFormat(
    weekEndingDates[weekEndingDates.length - 1],
    'yyyy-MM-dd'
  ).plus({ days: 7 });

  while (DateTime.local() > nextWeekEndingDate) {
    let quarter = `${nextWeekEndingDate.toFormat(
      'q'
    )}Q${nextWeekEndingDate.toFormat('yy')}`;

    weekEndingDates.push(nextWeekEndingDate.toFormat('yyyy-MM-dd'));

    if (!quarters.includes(quarter)) {
      quarters.push(quarter);
    }

    nextWeekEndingDate = nextWeekEndingDate.plus({ days: 7 });
  }

  return { weekEndingDates, quarters };
}

// General parsing mutation error messages
export function parseMutationError(err) {
  const { message, state } = err[0] || {};

  const stateErrors = state
    ? reduce(
        state,
        (acc, errorMessages, key) => {
          if (key) {
            // only show the errorMessages that come with key
            return acc.concat(errorMessages);
          } else {
            return acc;
          }
        },
        []
      )
    : [];

  return {
    message,
    stateErrors,
  };
}

export function safeParseJSON(raw) {
  let result;
  try {
    result = JSON.parse(raw);
  } catch (e) {}
  return result;
}
