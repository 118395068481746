import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';

// Hooks
import useAppReducer from 'Hooks/useAppReducer';

// Components
import { Dropdown, Image } from 'disco-ui';
import { Link } from 'react-router-dom';
import logo from 'Assets/logo.png';
import altria from 'Assets/icon_altria.png';
import rjr from 'Assets/icon_rjr.png';

function Navigation({ viewer, isFlexWorker }) {
  const [appState] = useAppReducer();
  const REACT_APP_WEBAPI_URL = process.env.REACT_APP_WEBAPI_URL;
  const logout = () => {
    window.location.replace(
      `${REACT_APP_WEBAPI_URL}/auth/signout?token=${appState.token?.accessToken}&provider=okta`,
      null
    );
    window.localStorage.removeItem('persist:token');
  };

  const NavigationDropdown = ({
    title,
    width,
    links,
    showReportConfigEditor = false,
  }) => (
    <Dropdown
      size="large"
      styles={{ zIndex: 1 }}
      display="inline-block"
      color="transparent"
      position="bottom left"
      title={title}
      width={width}
    >
      {links.map((link, index) => (
        <Link
          className="Navigation--link px-3 flex items-center"
          key={index}
          to={link.to}
        >
          {link.icon && (
            <Image url={link.icon} width={16} height={16} className="mr-2" />
          )}
          {link.text}
        </Link>
      ))}
      {showReportConfigEditor &&
        (/DATA|ENGINEER/i.test(adminRole) ? (
          <Link
            className="Navigation--link px-3 flex items-center"
            to={'/report_config_editor'}
          >
            Report Config Editor
          </Link>
        ) : null)}
    </Dropdown>
  );

  const { me } = viewer || {};
  const { adminRole } = me || {};

  return (
    <div className="w-full bg-[#333840] border-b border-gray-600 fixed t-0 b-0 l-0 r-0 z-[200]">
      <div className="flex justify-between items-center ml-auto mr-auto h-[64px] text-white">
        <div className="flex-[0_0_auto] self-stretch flex items-center justify-center px-4 h-[64px]">
          <img className="h-[28px]" src={logo} alt="Skupos Logo" />
        </div>
        {
          <div
            className="flex-[1_1] flex px-2 items-center"
            key="navigation-list"
          >
            <NavigationDropdown
              title="Organizations"
              width={160}
              links={[
                { to: '/chains', text: 'Chains' },
                { to: '/stores', text: 'Stores' },
              ]}
            />
            {isFlexWorker ? (
              <NavigationDropdown
                title="TSD Reporting"
                width={180}
                links={[
                  {
                    to: '/altria/reports',
                    text: 'Altria Submissions',
                    icon: altria,
                  },
                  { to: '/rjr/reports', text: 'RJR Submissions', icon: rjr },
                ]}
              />
            ) : (
              <NavigationDropdown
                title="TSD Reporting"
                width={180}
                links={[
                  {
                    to: '/altria/reports',
                    text: 'Altria Submissions',
                    icon: altria,
                  },
                  {
                    to: '/altria/accounts',
                    text: 'Altria Accounts',
                    icon: altria,
                  },
                  {
                    to: '/altria/quarterly',
                    text: 'Altria Quarterly',
                    icon: altria,
                  },
                  { to: '/rjr/reports', text: 'RJR Submissions', icon: rjr },
                  { to: '/rjr/accounts', text: 'RJR Accounts', icon: rjr },
                  { to: '/rjr/quarterly', text: 'RJR Quarterly', icon: rjr },
                  { to: '/report_config_queues', text: 'Report Config Queues' },
                ]}
                showReportConfigEditor
              />
            )}
            <NavigationDropdown
              title="Engage"
              width={160}
              links={[{ to: '/engage/promotions', text: 'Promotions' }]}
            />
            <NavigationDropdown
              title="Desktop Sync"
              width={160}
              links={[
                { to: '/sync/monitor', text: 'Monitor' },
                { to: '/sync/queue', text: 'Queue' },
              ]}
            />
            <NavigationDropdown
              title="Cloud Sync"
              width={160}
              links={[
                { to: '/cloud_sync/monitor', text: 'Monitor' },
                { to: '/cloud_sync/queue', text: 'Queue' },
              ]}
            />
          </div>
        }
        <div className="col-span-1 flex justify-end px-2">
          <div
            className="Navigation--link px-3 clickable text-[#fff] text-lg"
            onClick={logout}
          >
            Logout
          </div>
        </div>
      </div>
    </div>
  );
}

export default createFragmentContainer(Navigation, {
  viewer: graphql`
    fragment Navigation_viewer on Viewer {
      me {
        adminRole
      }
    }
  `,
});
