import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import { createReducer } from 'Reducers/helpers';

const initState = {
  accessToken: null,
  refreshToken: null,
  loggedIn: false
};

// handerlers
function set(state, { accessToken, refreshToken, loggedIn }) {
  const token = omitBy(
    {
      accessToken,
      refreshToken,
      loggedIn
    },
    isUndefined
  );

  return {
    ...state,
    ...token
  };
}

function reset(state) {
  return {
    ...initState
  };
}

const reducer = createReducer(
  {
    'TOKEN:SET': set,
    'TOKEN:RESET': reset
  },
  initState
);

export default reducer;
