// Ref: https://objectpartners.com/2018/12/05/migrate-from-react-loadable-to-react-suspense/
import { Component, lazy, Suspense } from 'react';

import { Button, Loader } from 'disco-ui';
import CachedIcon from '@mui/icons-material/Cached';

function ErrorLog({ error, errorInfo }) {
  return (
    <div
      className="w-full flex items-center justify-center"
      data-testid="Error__container"
    >
      <div
        className="flex flex-col mt-[70px] items-center justify-center h-full text-center p-3"
        style={{ maxWidth: '320px' }}
      >
        <CachedIcon fontSize="medium" style={{ color: '#ddd' }} />
        <h2>We&#39;ve updated the app!</h2>
        <p className="text-base mt-2 mb-4">
          Your local cache seems a bit outdated. Reload the page to sync up.
        </p>
        <Button
          display="block"
          color="primary"
          onClick={() => window.location.reload(true)}
        >
          Reload
        </Button>
        <br />
        <details style={{ whiteSpace: 'pre-wrap' }}>
          {error && error.toString()}
          <br />
          {errorInfo.componentStack}
        </details>
      </div>
    </div>
  );
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      const { errorComponent: ErrorComponent } = this.props;
      return <ErrorComponent {...this.state} />;
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default function Loadable(
  loader,
  { fallback = null, errorComponent = ErrorLog } = {}
) {
  const AsyncComponent = lazy(loader);
  return (props) => (
    <div data-testid="Loader__container">
      <ErrorBoundary errorComponent={errorComponent}>
        <Suspense
          fallback={
            fallback || <Loader className="flex items-center justify-center" />
          }
        >
          <AsyncComponent {...props} />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
