import { useRef, useEffect } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { getDirectionMap } from 'Services/Util';

import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';
import { Button } from 'disco-ui';
import Chevron from 'Components/Chevron';
import ListLoader from '../ListLoader';
import EmptyList from '../EmptyList';

function EngineerQueueHead({ orderBy, toggleOrderBy }) {
  const DIR = getDirectionMap(orderBy);

  return (
    <div className="ReportConfigQueuesList--head">
      <div className="flex items-center px-3 py-2 text-sm">
        <div className="flex-40 px-1 flex items-center">#</div>
        <div
          className="flex-80 px-1 flex items-center clickable"
          onClick={() => {
            toggleOrderBy('CHAIN_ID', DIR['CHAIN_ID']);
          }}
        >
          Chain ID <Chevron fontSize="small" direction={DIR['CHAIN_ID']} />
        </div>
        <div
          className="flex-80 px-1 flex items-center clickable"
          onClick={() => {
            toggleOrderBy('STORE_ID', DIR['STORE_ID']);
          }}
        >
          Store ID <Chevron fontSize="small" direction={DIR['STORE_ID']} />
        </div>
        <div className="flex-160 px-1 flex justify-center items-center">
          Data Ops Assignee
        </div>
        <div className="flex-160 px-1 flex justify-center items-center">
          Eng. Assignee
        </div>
        <div
          className="flex-160 px-1 flex justify-center items-center clickable"
          onClick={() => {
            toggleOrderBy('CREATED_AT', DIR['CREATED_AT']);
          }}
        >
          Created at <Chevron fontSize="small" direction={DIR['CREATED_AT']} />
        </div>
        <div
          className="flex-160 px-1 flex justify-center items-center clickable"
          onClick={() => {
            toggleOrderBy('UPDATED_AT', DIR['UPDATED_AT']);
          }}
        >
          Last updated{' '}
          <Chevron fontSize="small" direction={DIR['UPDATED_AT']} />
        </div>
        <div
          className="flex-1 pl-3 flex items-center clickable"
          onClick={() => {
            toggleOrderBy('TASK_EXCEPTION', DIR['TASK_EXCEPTION']);
          }}
        >
          Exception name{' '}
          <Chevron fontSize="small" direction={DIR['TASK_EXCEPTION']} />
        </div>
        <div className="flex-120 px-1 flex justify-center items-center">
          Action
        </div>
      </div>
    </div>
  );
}

function EngineerQueueItem({
  idx,
  dbId,
  storeId,
  chainId,
  dataOperator,
  engineer,
  updatedBy,
  createdAt,
  updatedAt,
  exception,
  showConfigure,
}) {
  const navigate = useNavigate();

  return (
    <div className="flex items-center px-3 py-2 text-sm">
      <div className="flex-40 px-1 flex items-center">{idx + 1}</div>
      <div className="flex-80 px-1 flex items-center">{chainId}</div>
      <div className="flex-80 px-1 flex items-center">{storeId}</div>
      <div className="flex-160 px-1 flex justify-center items-center text center">
        {dataOperator.user ? (
          <>
            {dataOperator.user}
            <br />
            {dataOperator.assignedAt}
          </>
        ) : (
          '-'
        )}
      </div>
      <div className="flex-160 px-1 flex justify-center items-center text center">
        {engineer.user ? (
          <>
            {engineer.user}
            <br />
            {engineer.assignedAt}
          </>
        ) : (
          '-'
        )}
      </div>
      <div className="flex-160 px-1 flex justify-center items-center">
        {createdAt}
      </div>
      <div className="flex-160 px-1 flex justify-center items-center text center">
        {updatedBy ? (
          <>
            {updatedBy}
            <br />
            {updatedAt}
          </>
        ) : (
          '-'
        )}
      </div>
      <div className="flex-1 pl-3 flex items-center">
        {exception.name ? exception.name : '-'}
      </div>
      <div className="px-1 flex justify-center items-center">
        {showConfigure && (
          <Button
            size="small"
            onClick={() => {
              navigate({
                pathname: '/report_config_editor',
                search: `?dbId=${dbId}`,
              });
            }}
          >
            Configure
          </Button>
        )}
      </div>
    </div>
  );
}

export default function EngineerQueue({
  me,
  configurationTasks,
  loading,
  orderBy,
  toggleOrderBy,
  needLoadMore,
  loadingMore,
}) {
  const cellMeasurerCache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 46,
    })
  );

  const tasks = configurationTasks || [];
  const showConfigure = me?.adminRole === 'ENGINEER';

  useEffect(() => {
    if (loading) {
      cellMeasurerCache.current.clearAll();
    }
  }, [loading]);

  function rowRenderer({ index, key, parent, style }) {
    const task = configurationTasks[index];
    return (
      <CellMeasurer
        cache={cellMeasurerCache.current}
        columnIndex={0}
        key={key}
        rowIndex={index}
        parent={parent}
      >
        {({ registerChild }) => (
          <div
            ref={registerChild}
            style={style}
            className="ReportConfigQueuesList--row flex flex align-stretch"
          >
            {
              <EngineerQueueItem
                idx={index}
                {...task}
                showConfigure={showConfigure}
              />
            }
          </div>
        )}
      </CellMeasurer>
    );
  }

  return (
    <div className="ReportConfigQueuesList flex-1 flex flex-col">
      <EngineerQueueHead orderBy={orderBy} toggleOrderBy={toggleOrderBy} />
      <div className={cn('ReportConfigQueuesList--result', { loadingMore })}>
        {loading ? (
          <ListLoader />
        ) : tasks.length ? (
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowCount={tasks.length}
                deferredMeasurementCache={cellMeasurerCache.current}
                rowHeight={cellMeasurerCache.current.rowHeight}
                rowRenderer={rowRenderer}
                onScroll={needLoadMore}
              />
            )}
          </AutoSizer>
        ) : (
          <EmptyList />
        )}
      </div>
      {loadingMore && <ListLoader />}
    </div>
  );
}
