import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';

import environment from 'Relay/environment';

const mutation = graphql`
  mutation UpdateConfigurationTaskQueueMutation(
    $input: UpdateConfigurationTaskQueueInput!
  ) {
    updateConfigurationTaskQueue(input: $input) {
      viewer {
        configurationTaskMetrics
      }
      configurationTask {
        id
        queue
      }
    }
  }
`;

function UpdateConfigurationTaskQueue(input, onCompleted, onError) {
  const variables = {
    input
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted,
    onError
  });
}

export default UpdateConfigurationTaskQueue;
