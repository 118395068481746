import { Card } from 'disco-ui';

// Relay
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';

function ReportConfigQueuesToolbar({ viewer }) {
  let { configurationTaskMetrics } = viewer || {};

  try {
    configurationTaskMetrics = JSON.parse(configurationTaskMetrics);
  } catch (err) {
    configurationTaskMetrics = null;
  }

  const {
    review_assigned: reviewAssigned,
    review_total: reviewTotal,
    configure_assigned: configureAssigned,
    configure_total: configureTotal,
    engineer,
    field,
    completed_last_week: completedLastWeek,
    completed_last_4_weeks: completedLast4Weeks,
  } = configurationTaskMetrics || {};

  return (
    <div className="ReportConfigQueuesToolbar flex items-center justify-between px-3">
      <h3 className="pr-3">Report Config Queues</h3>
      {configurationTaskMetrics && (
        <div className="flex items-center justify-between">
          <div className="mr-3">
            <h4 className="text center small mb-1">Progress</h4>
            <Card elevation="shadow1">
              <div className="flex jutify-between items-center p-2">
                <div className="text center px-2">
                  <h4>
                    {reviewAssigned}/{reviewTotal}
                  </h4>
                  <p className="text-[0.53rem] uppercase">Review</p>
                </div>
                <div className="text center px-2">
                  <h4>
                    {configureAssigned}/{configureTotal}
                  </h4>
                  <p className="text-[0.53rem] uppercase">Configure</p>
                </div>
                <div className="text center px-2">
                  <h4>{field}</h4>
                  <p className="text-[0.53rem] uppercase">Field</p>
                </div>
                <div className="text center px-2">
                  <h4>{engineer}</h4>
                  <p className="text-[0.53rem] uppercase">Engineer</p>
                </div>
              </div>
            </Card>
          </div>
          <div>
            <h4 className="text center small mb-1">Completed</h4>
            <Card elevation="shadow1">
              <div className="flex jutify-between items-center p-2">
                <div className="text center px-2">
                  <h4>{completedLastWeek}</h4>
                  <p className="text-[0.53rem] uppercase">Last week</p>
                </div>
                <div className="text center px-2">
                  <h4>{completedLast4Weeks}</h4>
                  <p className="text-[0.53rem] uppercase">Last 4 weeks</p>
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
}

export default createFragmentContainer(ReportConfigQueuesToolbar, {
  viewer: graphql`
    fragment ReportConfigQueuesToolbar_viewer on Viewer {
      configurationTaskMetrics
    }
  `,
});
