import { DateTime } from 'luxon';
import cn from 'classnames';

import { getEmailHandleFromUser, tolll } from 'Services/formatter';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const QUEUE_LABEL_MAP = {
  FIELD: 'Field',
  ENGINEERING: 'Engineer',
};

export default function ListItem({
  dbId,
  storeName,
  chainId,
  storeId,
  selectTask,
  queryParams,
  exception,
  createdAt,
  updatedAt,
  updatedBy,
}) {
  const ts = updatedAt ? updatedAt : createdAt;
  const showWarning =
    DateTime.local().diff(DateTime.fromSeconds(ts)).as('days') >= 5;
  return (
    <div
      className={cn('ReportConfigEditorSidebar--listItem p-3 clickable', {
        selected: dbId === queryParams.dbId,
      })}
      onClick={() => selectTask(dbId)}
    >
      {showWarning && <ErrorOutlineIcon className="warning" fontSize="small" />}
      <div>{storeName}</div>
      <div className="text-[0.53rem] mt-2">
        <div>Chain ID: {chainId}</div>
        <div>Store ID: {storeId}</div>
      </div>
      <div className="text-[0.53rem] mt-1">
        {exception
          ? `Manual configuration: ${QUEUE_LABEL_MAP[exception.queue]}`
          : 'Auto configuration'}
      </div>
      <div className="text-[0.53rem] mt-1">
        Updated: {updatedBy ? getEmailHandleFromUser(updatedBy) : 'system'}
        <br />
        {tolll(updatedBy ? updatedAt : createdAt)}
      </div>
    </div>
  );
}
