import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';

import environment from 'Relay/environment';

const mutation = graphql`
  mutation ToggleDataOperatorActiveTaskMutation(
    $input: ToggleDataOperatorActiveInput!
  ) {
    toggleDataOperatorActive(input: $input) {
      viewer {
        configurationTaskMetrics
      }
      user {
        id
        workQueueActive
        workQueueMetrics
      }
    }
  }
`;

function UpdateConfigurationTaskQueue(input, onCompleted, onError) {
  const variables = {
    input
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted,
    onError
  });
}

export default UpdateConfigurationTaskQueue;
