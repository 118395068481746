/**
 * @generated SignedSource<<20287ce5b5cf9f62ec2f90afdfc68749>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assignedAt",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  (v2/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = [
  (v9/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateConfigurationTaskFeedbackMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateConfigurationTaskFeedbackPayload",
        "kind": "LinkedField",
        "name": "createConfigurationTaskFeedback",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ConfigurationTask",
            "kind": "LinkedField",
            "name": "configurationTask",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ConfigurationTaskAssignee",
                "kind": "LinkedField",
                "name": "dataOperator",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ConfigurationTaskAssignee",
                "kind": "LinkedField",
                "name": "engineer",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ConfigurationTaskAssignee",
                "kind": "LinkedField",
                "name": "fieldMember",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "updatedBy",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ConfigurationTaskComments_configurationTask"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateConfigurationTaskFeedbackMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateConfigurationTaskFeedbackPayload",
        "kind": "LinkedField",
        "name": "createConfigurationTaskFeedback",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ConfigurationTask",
            "kind": "LinkedField",
            "name": "configurationTask",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ConfigurationTaskAssignee",
                "kind": "LinkedField",
                "name": "dataOperator",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ConfigurationTaskAssignee",
                "kind": "LinkedField",
                "name": "engineer",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ConfigurationTaskAssignee",
                "kind": "LinkedField",
                "name": "fieldMember",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "updatedBy",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dbId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ConfigurationTaskFeedback",
                "kind": "LinkedField",
                "name": "comments",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "message",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "postedAt",
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3a08d17c0f3ccd6f80374375acd2a280",
    "id": null,
    "metadata": {},
    "name": "CreateConfigurationTaskFeedbackMutation",
    "operationKind": "mutation",
    "text": "mutation CreateConfigurationTaskFeedbackMutation(\n  $input: CreateConfigurationTaskFeedbackInput!\n) {\n  createConfigurationTaskFeedback(input: $input) {\n    configurationTask {\n      dataOperator {\n        user {\n          email\n          id\n        }\n        assignedAt\n      }\n      engineer {\n        user {\n          email\n          id\n        }\n        assignedAt\n      }\n      fieldMember {\n        user {\n          email\n          id\n        }\n        assignedAt\n      }\n      updatedBy {\n        email\n        id\n      }\n      updatedAt\n      ...ConfigurationTaskComments_configurationTask\n      id\n    }\n  }\n}\n\nfragment ConfigurationTaskComments_configurationTask on ConfigurationTask {\n  dbId\n  comments {\n    message\n    postedAt\n    user {\n      email\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "006cbb10127bd07ea4b6755080ba1b55";

module.exports = node;
