function genKey(id) {
  return `ReportConfig:${id}`;
}

function genTsKey(id) {
  return `ReportConfig:${id}:ts`;
}

function get(id) {
  return window.localStorage.getItem(genKey(id));
}

function getTs(id) {
  return window.localStorage.getItem(genTsKey(id));
}

function set(id, value) {
  window.localStorage.setItem(genKey(id), value);
  window.localStorage.setItem(genTsKey(id), Date.now());
}

function remove(id) {
  window.localStorage.removeItem(genKey(id));
  window.localStorage.removeItem(genTsKey(id));
}

const ConfigLocalStorage = {
  get,
  getTs,
  set,
  remove
};

export default ConfigLocalStorage;
