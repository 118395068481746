import { useState } from 'react';
import { getEmailHandleFromUser, tolll } from 'Services/formatter';

// Relay
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import CreateConfigurationTaskFeedback from 'Mutations/CreateConfigurationTaskFeedback';

import { Button, TextArea } from 'disco-ui';

function Comment({ message, postedAt, user }) {
  return (
    <div className="comment">
      <div className="text-base">
        {user ? getEmailHandleFromUser(user) : 'System'}{' '}
        <span className="text-sm">({tolll(postedAt)})</span>
      </div>
      <pre>{message}</pre>
    </div>
  );
}

function ConfigurationTaskComments({ configurationTask }) {
  const { dbId, comments } = configurationTask || [];

  const [message, setMessage] = useState('');

  function submitMessage() {
    if (message) {
      CreateConfigurationTaskFeedback(
        {
          configurationTaskId: dbId,
          message,
        },
        (res) => {
          setMessage('');
        },
        (err) => {}
      );
    }
  }

  return (
    <>
      <div className="comments mt-3">
        {comments &&
          comments.map((comment, idx) => <Comment key={idx} {...comment} />)}
      </div>

      <TextArea
        height={72}
        resize="vertical"
        placeholder="Write comment"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      {message && (
        <div className="flex items-center mt-2">
          <Button
            size="small"
            color="primary"
            className="mr-2"
            onClick={submitMessage}
          >
            Save
          </Button>
          <Button size="small" color="white" onClick={() => setMessage('')}>
            Cancel
          </Button>
        </div>
      )}
    </>
  );
}

export default createFragmentContainer(ConfigurationTaskComments, {
  configurationTask: graphql`
    fragment ConfigurationTaskComments_configurationTask on ConfigurationTask {
      dbId
      comments {
        message
        postedAt
        user {
          email
        }
      }
    }
  `,
});
