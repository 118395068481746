/* eslint-disable import/first */
import { useRef, useMemo } from 'react';
import {
  Route,
  Navigate,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { cache as relayCache } from 'Relay/environment';
import {
  checkFlexWorkforce,
  checkCustomerSupportBlacklist,
} from 'Services/Util';

// Relay
import graphql from 'babel-plugin-relay/macro';
import createQueryRenderer from 'Relay/createQueryRenderer';

// Consts
import UseFixture from 'Config/UseFixture';
import { webapi } from 'Config/Hosts';

// Hooks
import useAppReducer from 'Hooks/useAppReducer';
import { NavContext, useNav } from 'Hooks/useNav';

// Navigation
import Navigation from 'Navigation';

// Code splitting
import Loadable from 'Components/Loadable';
import ReportConfigQueues from './ReportConfigQueues';
import ReportConfigEditor from './ReportConfigEditor';
// Routes
import Auth from 'Routes/Auth';
function OktaOauth2() {
  const navigate = useNavigate();
  const location = useLocation();
  try {
    window.sessionStorage.setItem('entryLocation', JSON.stringify(location));
  } catch (err) {}
  if (UseFixture) {
    navigate('/auth/admin?accessToken=foo&refreshToken=foo');
  } else {
    window.location.replace(`${webapi}/auth/okta`);
  }
  return null;
}

const RjrReports = Loadable(() => import('./RjrReports'));
const RjrAccounts = Loadable(() => import('./RjrAccounts'));
const AltriaReports = Loadable(() => import('./AltriaReports'));
const AltriaAccounts = Loadable(() => import('./AltriaAccounts'));
const Stores = Loadable(() => import('./Stores'));
const Chains = Loadable(() => import('./Chains'));
const SyncMonitor = Loadable(() => import('./SyncMonitor'));
const SyncQueue = Loadable(() => import('./SyncQueue'));
const CloudSyncMonitor = Loadable(() => import('./CloudSyncMonitor'));
const CloudSyncQueue = Loadable(() => import('./CloudSyncQueue'));
const AltriaQuarterly = Loadable(() => import('./AltriaQuarterly'));
const RjrQuarterly = Loadable(() => import('./RjrQuarterly'));
const Skata = Loadable(() => import('./Skata'));
const EngagePrograms = Loadable(() => import('./EngagePrograms'));
const DataOpsTools = Loadable(() => import('./DataOpsTools'));
const DataOpsToolsMonitor = Loadable(() => import('./DataOpsToolsMonitor'));

//Flex Worker elements
const FWChains = Loadable(() => import('./Chains/FWChains'));

// Style
import './style.scss';

const LoginRoutes = (props) => {
  const nav = useNav(props);

  const { viewer } = props;
  const { me } = viewer || {};
  const { username = '' } = me || {};

  const isFlexWorker = useMemo(() => checkFlexWorkforce(username), [username]);

  const disableAccountAndFileSubmissionAccess = useMemo(
    () => checkCustomerSupportBlacklist(username),
    [username]
  );

  if (isFlexWorker) {
    return (
      <NavContext.Provider value={nav}>
        <Navigation viewer={viewer} isFlexWorker={isFlexWorker} />
        <Routes>
          <Route path="/chains">
            <Route index element={<FWChains />} />
            <Route path="/chains/:chainId" element={<FWChains />} />
          </Route>
          <Route path="/stores">
            <Route index element={<Stores />} />
            <Route path="/stores/:storeId" element={<Stores />} />
          </Route>
          <Route path="/altria/reports">
            <Route
              index
              element={
                <AltriaReports
                  disableAccountAndFileSubmissionAccess={
                    disableAccountAndFileSubmissionAccess
                  }
                />
              }
            />
            <Route
              path="/altria/reports/:listType(store)"
              element={
                <AltriaReports
                  disableAccountAndFileSubmissionAccess={
                    disableAccountAndFileSubmissionAccess
                  }
                />
              }
            />
          </Route>
          <Route path="/rjr/reports">
            <Route
              index
              elemet={
                <RjrReports
                  disableAccountAndFileSubmissionAccess={
                    disableAccountAndFileSubmissionAccess
                  }
                />
              }
            />
            <Route
              path="/rjr/reports/:listType(store)"
              element={
                <RjrReports
                  disableAccountAndFileSubmissionAccess={
                    disableAccountAndFileSubmissionAccess
                  }
                />
              }
            />
          </Route>
          <Route path="/sync/monitor" element={<SyncMonitor />} />
          <Route path="/sync/queue">
            <Route index element={<SyncQueue />} />
            <Route path="/sync/queue/:storeId" element={<SyncQueue />} />
          </Route>
          <Route path="/cloud_sync/monitor" element={<CloudSyncMonitor />} />
          <Route path="/cloud_sync/queue">
            <Route index element={<CloudSyncQueue />} />
            <Route
              path="/cloud_sync/queue/:storeId"
              element={<CloudSyncQueue />}
            />
          </Route>
          <Route path="*" element={<Navigate to="/chains" replace />} />
        </Routes>
      </NavContext.Provider>
    );
  } else {
    return (
      <NavContext.Provider value={nav}>
        <Navigation viewer={viewer} isFlexWorker={isFlexWorker} />
        <Routes>
          <Route path="/chains">
            <Route index element={<Chains />} />
            <Route path="/chains/:chainId" element={<Chains />} />
          </Route>
          <Route path="/stores">
            <Route index element={<Stores />} />
            <Route path="/stores/:storeId" element={<Stores />} />
          </Route>
          <Route path="/altria/reports">
            <Route
              index
              element={
                <AltriaReports
                  disableAccountAndFileSubmissionAccess={
                    disableAccountAndFileSubmissionAccess
                  }
                />
              }
            />
            <Route
              path="/altria/reports/:listType"
              element={
                <AltriaReports
                  disableAccountAndFileSubmissionAccess={
                    disableAccountAndFileSubmissionAccess
                  }
                />
              }
            />
          </Route>
          <Route path="/altria/accounts">
            <Route
              index
              element={
                <AltriaAccounts
                  disableAccountAndFileSubmissionAccess={
                    disableAccountAndFileSubmissionAccess
                  }
                />
              }
            />
            <Route
              path="/altria/accounts/:accountId"
              element={
                <AltriaAccounts
                  disableAccountAndFileSubmissionAccess={
                    disableAccountAndFileSubmissionAccess
                  }
                />
              }
            />
          </Route>
          <Route path="/altria/quarterly" element={<AltriaQuarterly />} />
          <Route path="/rjr/reports">
            <Route
              index
              element={
                <RjrReports
                  disableAccountAndFileSubmissionAccess={
                    disableAccountAndFileSubmissionAccess
                  }
                />
              }
            />
            <Route
              path="/rjr/reports/:listType"
              element={
                <RjrReports
                  disableAccountAndFileSubmissionAccess={
                    disableAccountAndFileSubmissionAccess
                  }
                />
              }
            />
          </Route>
          <Route path="/rjr/accounts">
            <Route
              index
              element={
                <RjrAccounts
                  disableAccountAndFileSubmissionAccess={
                    disableAccountAndFileSubmissionAccess
                  }
                />
              }
            />
            <Route
              path="/rjr/accounts/:reportAccountId"
              element={
                <RjrAccounts
                  disableAccountAndFileSubmissionAccess={
                    disableAccountAndFileSubmissionAccess
                  }
                />
              }
            />
          </Route>
          <Route path="/rjr/quarterly" element={<RjrQuarterly />} />
          <Route path="/sync/monitor" element={<SyncMonitor />} />
          <Route path="/sync/queue">
            <Route index element={<SyncQueue />} />
            <Route path="/sync/queue/:storeId" element={<SyncQueue />} />
          </Route>
          <Route path="/cloud_sync/monitor" element={<CloudSyncMonitor />} />
          <Route path="/cloud_sync/queue">
            <Route index element={<CloudSyncQueue />} />
            <Route
              path="/cloud_sync/queue/:storeId"
              element={<CloudSyncQueue />}
            />
          </Route>
          <Route path="/queries" element={<Skata />} />
          <Route path="/engage/promotions">
            <Route index element={<EngagePrograms />} />
            <Route
              path="/engage/promotions/:programId"
              element={<EngagePrograms />}
            />
          </Route>
          <Route
            path="/data_ops_tools_monitor"
            element={<DataOpsToolsMonitor />}
          />
          <Route path="/data_ops_tools" element={<DataOpsTools />} />
          <Route
            path="/report_config_queues/*"
            element={<ReportConfigQueues />}
          />
          <Route
            path="/report_config_editor"
            element={<ReportConfigEditor />}
          />
          <Route path="*" element={<Navigate to="/chains" replace />} />
        </Routes>
      </NavContext.Provider>
    );
  }
};

const LoginRoutesWQuery = createQueryRenderer(
  LoginRoutes,
  graphql`
    query RoutesQuery {
      viewer {
        me {
          username
        }
        ...Navigation_viewer
      }
    }
  `
);

function AppRoutes() {
  const [appState] = useAppReducer();

  const { loggedIn } = appState.token;

  const prevLoggedIn = useRef();

  if (loggedIn !== prevLoggedIn.current) {
    if (!loggedIn) relayCache.clear();
    prevLoggedIn.current = loggedIn;
  }

  if (loggedIn) {
    return <LoginRoutesWQuery />;
  } else {
    return (
      <Routes>
        <Route path="/auth/admin" element={<Auth />} />
        <Route path="*" element={<OktaOauth2 />} />
      </Routes>
    );
  }
}

export default AppRoutes;
