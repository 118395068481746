import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';

import environment from 'Relay/environment';

const mutation = graphql`
  mutation CreateConfigurationTaskFeedbackMutation(
    $input: CreateConfigurationTaskFeedbackInput!
  ) {
    createConfigurationTaskFeedback(input: $input) {
      configurationTask {
        dataOperator {
          user {
            email
          }
          assignedAt
        }
        engineer {
          user {
            email
          }
          assignedAt
        }
        fieldMember {
          user {
            email
          }
          assignedAt
        }
        updatedBy {
          email
        }
        updatedAt
        ...ConfigurationTaskComments_configurationTask
      }
    }
  }
`;

function GenerateReports(input, onCompleted, onError) {
  const variables = {
    input
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted,
    onError
  });
}

export default GenerateReports;
