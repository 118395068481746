import graphql from 'babel-plugin-relay/macro';

export default graphql`
  query ReportConfigEditorQuery {
    viewer {
      me {
        adminRole
      }
    }
  }
`;
