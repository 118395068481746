import { Loader } from 'disco-ui';

import DelayRender from 'Components/DelayRender';

export default function ListLoader() {
  return (
    <DelayRender delay={200}>
      <div className="ReportConfigQueuesList--row py-3">
        <Loader size={24} color="primary" />
      </div>
    </DelayRender>
  );
}
