import { create } from 'apisauce';
import { webapi, posAdmin } from 'Config/Hosts';
import { getAppReducer } from 'app';

/*
  This withAuth is similar to what I did for relay Network layer
  It will assign accessToken to headers everytime before making request
  If accessToken expired, it will refresh the token and retry previous request

  Just need to keep an eye on we might potentially run into race condition

  po
*/

function withAuth(api) {
  const { axiosInstance } = api;
  axiosInstance.interceptors.request.use((config) => {
    const [state] = getAppReducer();
    const { accessToken } = state.token || {};
    config.headers.Authorization = accessToken;
    return config;
  });

  axiosInstance.interceptors.response.use(
    (res) => res,
    (error) => {
      const [state, dispatch] = getAppReducer();
      const { refreshToken } = state.token || {};

      // 401 UNAUTHORIZED
      if (error.response.status === 401) {
        if (refreshToken) {
          return refresh(refreshToken).then((response) => {
            if (response.ok) {
              const accessToken = response.data['access_token'];
              const refreshToken = response.data['refresh_token'];
              dispatch({
                type: 'TOKEN:SET',
                accessToken,
                refreshToken,
              });
              return axiosInstance.request(error.config);
            } else {
              dispatch({
                type: 'TOKEN:RESET',
              });
              return Promise.reject(error);
            }
          });
        } else {
          // clear invalid accessToken
          dispatch({
            type: 'TOKEN:RESET',
          });
        }
      }

      return Promise.reject(error);
    }
  );
}

const authApi = create({
  baseURL: `${webapi}/`,
  header: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
  },
  timeout: 10000,
});
const webapiRestApi = create({
  baseURL: `${webapi}/`,
  header: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});
const posAdminApi = create({
  baseURL: `${posAdmin}/api`,
  header: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
  },
  timeout: 10000,
});

// setup interceptors
withAuth(authApi);
withAuth(webapiRestApi);
withAuth(posAdminApi);

// webapi auth
function refresh(refreshToken) {
  return authApi.post(`/auth/refresh/${refreshToken}`);
}
function logout(refreshToken) {
  return authApi.delete(`/auth/${refreshToken}`);
}
function generatePassword(userId) {
  return authApi.post(`/generate_password/${userId}`);
}
// webapi non-graphql api
const createAccount = (configS3Path) =>
  webapiRestApi.post(`/altria_account`, {
    altria_account: { config_s3_path: configS3Path },
  });
const updateAccount = (configS3Path) =>
  webapiRestApi.put(`/altria_account`, {
    altria_account: { config_s3_path: configS3Path },
  });
const fetchAccount = (accountId) =>
  webapiRestApi.get(`/altria_account/${accountId}`);
const listQueries = () => webapiRestApi.get(`/queries`);
const runQuery = (format, queryId, queryParams = {}) =>
  webapiRestApi.get(`/queries/${format}/${queryId}`, queryParams);
const fetchEngageTransaction = (storeId, timeframe) =>
  webapiRestApi.get(`/engage_transaction/${storeId}/${timeframe}`);
const fetchReportView = (dbId, accessToken) =>
  webapiRestApi.get(`/open_report/${dbId}?token=${accessToken}`);
const fetchDownloadReports = (query) =>
  webapiRestApi.get(`/download_reports${query}`);

// pos-admin api
const getHealth = () => posAdminApi.get(`/health`);
const getLog = (query) => posAdminApi.get(`/log`, query);
const getVtlogpdlist = (storeId) =>
  posAdminApi.get(`/verifone/vtlogpdlist/${storeId}`);
const getJobHealth = (storeId) => posAdminApi.get(`/queue/${storeId}/health`);
const getJobs = (storeId, query = {}) =>
  posAdminApi.get(`/queue/${storeId}`, query);
const createJob = (storeId, data, options) =>
  posAdminApi.post(`/queue/${storeId}`, { data, options });
const flushJobs = (storeId) => posAdminApi.get(`/queue/${storeId}/flush`);
const getConfigs = (storeId) =>
  posAdminApi.get(`/localconfig/configs/${storeId}`);
const getConfig = (storeId) =>
  posAdminApi.get(`/localconfig/config/${storeId}`);
const fetchValidatedPricebook = (storeId) =>
  posAdminApi.get(`/pricebook/validate/${storeId}`);
const fetchPricebook = (storeId) =>
  posAdminApi.get(`/pricebook/fetch/${storeId}`);
const fetchPosAppInfo = (storeId) =>
  posAdminApi.get(`/verifone/vappinfo/${storeId}`);

const WebApi = {
  // webapi auth
  refresh,
  logout,
  generatePassword,
  // webapi non-graphql
  createAccount,
  updateAccount,
  fetchAccount,
  listQueries,
  runQuery,
  fetchEngageTransaction,
  fetchReportView,
  fetchDownloadReports,
  // pos-admin
  getHealth,
  getLog,
  getVtlogpdlist,
  getJobHealth,
  getJobs,
  createJob,
  flushJobs,
  getConfigs,
  getConfig,
  fetchValidatedPricebook,
  fetchPricebook,
  fetchPosAppInfo,
};
export default WebApi;
