/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

function DelayRender(props) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    let timeout;
    if (props.delay) {
      timeout = setTimeout(() => {
        setShow(true);
      }, props.delay);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return show || !props.delay ? props.children : null;
}

export default DelayRender;
