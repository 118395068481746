import { DateTime } from 'luxon';

// get email handle
export function getEmailHandle(email) {
  return email ? email.split('@')[0] : '';
}

export function getEmailHandleFromUser(user) {
  return user && user.email ? getEmailHandle(user.email) : '';
}

// display: (123)456-7890
// input: 1234567890
export function formatPhoneNumber(s, type = 'display') {
  let m = s.match(/(\d{3})(\d{3})(\d{4})$/);
  if (m) {
    return type === 'display'
      ? `(${m[1]}) ${m[2]}-${m[3]}`
      : `${m[1]}${m[2]}${m[3]}`;
  } else {
    return s;
  }
}

export function toEST(ts) {
  const date = DateTime.fromSeconds(ts);
  return date.setZone('America/New_York');
}

export function toEstFormatted(ts) {
  if (!ts) return '-';
  return toEST(ts).toFormat('yyyy-MM-dd');
}

export function toUTCFormatted(ts) {
  if (!ts) return '-';
  return DateTime.fromSeconds(ts, 'X').toUTC().toFormat('yyyy-MM-dd');
}

export function toLocalFormatted(ts) {
  if (!ts) return '-';
  return DateTime.fromSeconds(ts, 'X').toFormat('yyyy-MM-dd');
}

export function tolll(ts) {
  return ts
    ? DateTime.fromSeconds(ts).toLocaleString(DateTime.DATETIME_MED)
    : '-';
}

// default ET
export function getProgramDate({ beginAt, endAt }) {
  const beginDate = toEST(beginAt);
  const endDate = toEST(endAt);
  return `${beginDate.toFormat('yyyy/MM/dd')} - ${endDate.toFormat(
    'yyyy/MM/dd'
  )}`;
}
