import { useState } from 'react';
import cn from 'classnames';

import { Button, Alert, Loader } from 'disco-ui';

import api from 'Services/WebApi';

import './style.scss';

const TransactionResult = ({ result }) => {
  const { status, date, lifecycle, number_entered, discounts } = result;

  const displayConcurrent = (quantity, concurrent) => {
    let displayValue;
    if (quantity >= 2 && !!concurrent) {
      displayValue = (
        <p className="text-primary text-center text-[12px]">Present</p>
      );
    } else if ((quantity >= 2 && !concurrent) || quantity === 1) {
      displayValue = (
        <p className="text-danger text-center text-[12px]">Absent</p>
      );
    } else {
      displayValue = <p className="text-danger text-center text-[12px]">N/A</p>;
    }
    return displayValue;
  };

  const displayIssued = (quantity, issued) => {
    let displayValue;
    if (quantity >= 2 && !!issued) {
      displayValue = (
        <p className="text-primary text-center text-[12px]">Yes</p>
      );
    } else if ((quantity >= 2 && !issued) || quantity === 1) {
      displayValue = <p className="text-danger text-center text-[12px]">No</p>;
    } else {
      displayValue = <p className="text-danger text-center text-[12px]">N/A</p>;
    }
    return displayValue;
  };

  const rowNum = discounts.length > 1 ? '2' : '';

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Test Transaction</th>
            <th>Date</th>
            <th>Transaction Lifecycle</th>
            <th>800 Number</th>
            <th>UPCs in Purchase Basket</th>
            <th>Quantity of PM USA Line Item</th>
            <th>Concurrent Discount</th>
            <th>POS Issued Engage Discount</th>
          </tr>
        </thead>
        {!!date && (
          <tbody>
            <tr>
              <td rowSpan={rowNum}>
                <p
                  className={cn(
                    'capitalize',
                    status.includes('success')
                      ? 'text-primary text-center text-[12px]'
                      : status.includes('failed')
                      ? 'text-danger text-center text-[12px]'
                      : 'text-sm'
                  )}
                >
                  {status}
                </p>
              </td>
              <td rowSpan={rowNum}>{date}</td>
              <td rowSpan={rowNum}>
                <p
                  className={cn(
                    'capitalize',
                    lifecycle.includes('complete')
                      ? 'text-primary text-center text-[12px]'
                      : lifecycle.includes('cancelled')
                      ? 'text-danger text-center text-[12px]'
                      : 'text-sm'
                  )}
                >
                  {lifecycle}
                </p>
              </td>
              <td rowSpan={rowNum}>
                {number_entered ? (
                  <p className="text-primary text-center text-[12px]">
                    Entered
                  </p>
                ) : (
                  <p className="text-danger text-center text-[12px]">
                    Not Entered
                  </p>
                )}
              </td>
              <td>
                <p
                  className={cn(
                    discounts.length > 1 || !discounts[0].upc
                      ? 'text-danger text-center text-[12px]'
                      : 'text-primary text-center text-[12px]'
                  )}
                >
                  {!discounts[0].upc ? 'N/A' : discounts[0].upc}
                </p>
              </td>
              <td>
                <p
                  className={cn(
                    discounts.length > 1 ||
                      !discounts[0].quantity ||
                      discounts[0].quantity < 2
                      ? 'text-danger text-center text-[12px]'
                      : 'text-primary text-center text-[12px]'
                  )}
                >
                  {!discounts[0].quantity ? 'N/A' : discounts[0].quantity}
                </p>
              </td>
              <td>
                {displayConcurrent(
                  discounts[0].quantity,
                  discounts[0].concurrent
                )}
              </td>
              <td>
                {displayIssued(discounts[0].quantity, discounts[0].issued)}
              </td>
            </tr>
            {discounts.length > 1 && (
              <tr>
                <td>
                  <p className="text-danger text-center text-[12px]">
                    {discounts[1].upc}
                  </p>
                </td>
                <td>
                  <p className="text-danger text-center text-[12px]">
                    {discounts[1].quantity}
                  </p>
                </td>
                <td>
                  {displayConcurrent(
                    discounts[1].quantity,
                    discounts[1].concurrent
                  )}
                </td>
                <td>
                  {displayIssued(discounts[1].quantity, discounts[1].issued)}
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>
      <div className="mt-2">
        {!date && <Alert color="warning">No Results</Alert>}
      </div>
    </div>
  );
};

export default function RecentEngageTransaction({ storeId }) {
  const [result, setResult] = useState();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getTransaction = (timeframe) => {
    setLoading(true);
    setResult();
    api.fetchEngageTransaction(storeId, timeframe).then((response) => {
      if (response.ok && response.data) {
        setResult(response.data);
        setError(null);
        setLoading(false);
      } else {
        console.log('fetchEngageTransaction error', response);
        setError(
          response.data ? response.data.errors.join('') : response.problem
        );
        setLoading(false);
      }
    });
  };

  return (
    <div className="border-b border-solid border-black p-[0_16px_16px] m-[0_-1em]">
      <div className="flex items-center mb-3">
        <h4>Most Recent Engage Transaction</h4>
        <Button
          className="mx-4"
          size="small"
          disabled={loading}
          onClick={() => getTransaction('30mins')}
        >
          Search Past 30 Minutes
        </Button>
        <Button
          size="small"
          disabled={loading}
          onClick={() => getTransaction('6months')}
        >
          Search Past 6 Months
        </Button>
      </div>

      {result ? (
        <TransactionResult result={result} />
      ) : loading && !error ? (
        <Loader color="primary" size={36} />
      ) : (
        !error && <Alert>Run Search For Results</Alert>
      )}
      {!!error && <p className="text-danger">{error}</p>}
    </div>
  );
}
