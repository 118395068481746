// Higher Order Component to mix redux and relay together
import { QueryRenderer } from 'react-relay';

import environment from './environment';

import { Alert } from 'disco-ui';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const createQueryRenderer =
  (Component, query, getVariables = null, Config, env = null) =>
  (originalProps) => {
    let variables;
    if (typeof getVariables === 'function') {
      variables = getVariables(originalProps);
    } else {
      variables = getVariables;
    }

    const { cacheConfig = null, Loading, Error } = Config || {};

    return (
      <QueryRenderer
        environment={env || environment}
        query={query}
        variables={variables}
        cacheConfig={cacheConfig}
        render={({ error, props, retry }) => {
          if (error) {
            return Error ? (
              <Error error={error} retry={retry} />
            ) : (
              <Alert
                className="mb-3"
                color="danger"
                icon={<ErrorOutlineIcon />}
              >
                {error.message}
              </Alert>
            );
          } else if (props) {
            return <Component {...originalProps} {...props} />;
          } else if (Loading) {
            return <Loading />;
          } else {
            return null;
          }
        }}
      />
    );
  };

export default createQueryRenderer;
