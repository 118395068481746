import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

function Chevron({ direction, ...restProps }) {
  const Chevron =
    direction === 'ASC'
      ? ExpandLessIcon
      : direction === 'DESC'
      ? ExpandMoreIcon
      : UnfoldMoreIcon;

  return <Chevron {...restProps} />;
}

export default Chevron;
