import { useMemo, useEffect } from 'react';

// Relay
import graphql from 'babel-plugin-relay/macro';
import createQueryRenderer from 'Relay/createQueryRenderer';

import EmptyItem from '../EmptyItem';
import ListItem from '../ListItem';

function mapTask({ node }) {
  const { store, ...rest } = node || {};

  const { dbId: storeId, name: storeName, storeChain } = store || {};

  const { dbId: chainId } = storeChain || {};

  return {
    ...rest,
    storeId,
    storeName,
    chainId,
  };
}

function EngineerSidebar({
  viewer,
  queryParams,
  selectTask,
  nextTaskInQueue,
  setNextTaskInQueue,
}) {
  const { configurationTasks } = viewer || {};

  const tasks = useMemo(() => {
    const nodes = configurationTasks.edges
      ? configurationTasks.edges.map(mapTask)
      : [];

    return nodes.filter(
      (t) => t.completion === 'INCOMPLETE' && t.queue === 'ENGINEERING'
    );
  }, [configurationTasks]);

  useEffect(() => {
    if (nextTaskInQueue) {
      const dbId = tasks.length ? tasks[0].dbId : null;
      selectTask(dbId);
      setNextTaskInQueue(null);
    }
  }, [nextTaskInQueue, setNextTaskInQueue, tasks, selectTask]);

  return (
    <div className="ReportConfigEditorSidebar">
      <div className="ReportConfigEditorSidebar--divider px-3 py-2">
        <div className="text-[0.53rem] uppercase left">Engineer</div>
      </div>
      {tasks.length ? (
        tasks.map((task) => (
          <ListItem
            key={task.id}
            {...task}
            selectTask={selectTask}
            queryParams={queryParams}
          />
        ))
      ) : (
        <EmptyItem />
      )}
    </div>
  );
}

export default createQueryRenderer(
  EngineerSidebar,
  graphql`
    query EngineerSidebarQuery {
      viewer {
        configurationTasks(
          first: 300
          filters: { completed: INCOMPLETE, queues: [ENGINEERING] }
        ) {
          edges {
            node {
              id
              dbId
              queue
              completion
              store {
                name
                dbId
                storeChain {
                  dbId
                }
              }
              createdAt
              updatedAt
              updatedBy {
                email
              }
            }
          }
        }
      }
    }
  `
);
