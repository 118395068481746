// skupos cli

export default function skucil(cfg) {
  const { getAppReducer } = cfg || {};

  function launchFrom(host) {
    if (getAppReducer) {
      const [state] = getAppReducer();
      const { accessToken, refreshToken } = state.token || {};

      if (refreshToken) {
        host = host.replace(/\/$/, ''); // remove the last /

        window.location.replace(
          `${host}/auth/admin?accessToken=${accessToken}&refreshToken=${refreshToken}`
        );
      }
    }
  }

  function launchFromLocal(port = 4000) {
    launchFrom(`http://localhost:${port}`);
  }

  return {
    launchFrom,
    launchFromLocal
  };
}
