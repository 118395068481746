/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useEffect, useState, useRef } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import reduce from 'lodash/reduce';

// Relay
import graphql from 'babel-plugin-relay/macro';
import createQueryRenderer from 'Relay/createQueryRenderer';
import { createPaginationContainer } from 'react-relay';

import { getEmailHandleFromUser, tolll } from 'Services/formatter';

// Components
import ReportConfigQueuesToolbar from './ReportConfigQueuesToolbar';
import ReportConfigQueuesTabs from './ReportConfigQueuesTabs';
import ReviewQueue from './ReviewQueue';
import ConfigureQueue from './ConfigureQueue';
import FieldQueue from './FieldQueue';
import EngineerQueue from './EngineerQueue';
import UserManagement from './UserManagement';

import './style.scss';

const QUEUE_COMP_MAP = {
  data_ops_review: ReviewQueue,
  data_ops_configuration: ConfigureQueue,
  field: FieldQueue,
  engineering: EngineerQueue,
};

const QUEUE_LABEL_MAP = {
  DATA_OPS_REVIEW: 'Data Ops',
  DATA_OPS_CONFIGURATION: 'Data Ops',
  FIELD: 'Field',
  ENGINEERING: 'Engineer',
};

const FETCH_COUNTS = 100;
const LOAD_MORE_COUNTS = 100;
const LOAD_MORE_THRESHOLD = 1500;
const INIT_ORDER_BY = [{ sort: 'CREATED_AT', direction: 'DESC' }];

function formatAssignee(assignee) {
  const { user, assignedAt } = assignee || {};
  return {
    user: getEmailHandleFromUser(user),
    assignedAt: tolll(assignedAt),
  };
}

function formatException(exception) {
  const { name, queue } = exception || {};
  return {
    name,
    queue: QUEUE_LABEL_MAP[queue],
  };
}

function transformConfigurationTask({ node }) {
  let {
    store,
    dataOperator,
    engineer,
    fieldMember,
    updatedBy,
    createdAt,
    updatedAt,
    exception,
    ...rest
  } = node || {};
  let { dbId: storeId, storeChain, firstEngagePingAt } = store || {};
  const { dbId: chainId } = storeChain || {};
  dataOperator = formatAssignee(dataOperator);
  engineer = formatAssignee(engineer);
  fieldMember = formatAssignee(fieldMember);
  updatedBy = getEmailHandleFromUser(updatedBy);
  createdAt = tolll(createdAt);
  updatedAt = tolll(updatedAt);
  firstEngagePingAt = tolll(firstEngagePingAt);
  exception = formatException(exception);
  return {
    ...rest,
    storeId,
    chainId,
    dataOperator,
    engineer,
    fieldMember,
    updatedBy,
    createdAt,
    updatedAt,
    firstEngagePingAt,
    exception,
  };
}

function ReportConfigQueues(props) {
  const navigate = useNavigate();
  const { queue } = useParams();
  const { viewer } = props;
  const { me } = viewer || {};
  const [filters, setFilters] = useState(null);
  const [orderBy, setOrderBy] = useState(INIT_ORDER_BY);
  const [loadingMore, setLoadingMore] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const prevQueue = useRef('');

  // transform edges to array of nodes
  const configurationTasks = useMemo(() => {
    return viewer.configurationTasks?.edges
      ? viewer.configurationTasks.edges.map(transformConfigurationTask)
      : [];
  }, [viewer.configurationTasks]);

  const toggleOrderBy = (sort, prevDir) => {
    let newOrderBy;
    if (prevDir === 'DESC') {
      // remove from array
      newOrderBy = orderBy.filter((o) => o.sort !== sort);
    } else if (prevDir === 'ASC') {
      // change to DESC
      newOrderBy = reduce(
        orderBy,
        (acc, o) => {
          if (o.sort === sort) {
            acc.push({
              sort,
              direction: 'DESC',
            });
          } else {
            acc.push(o);
          }
          return acc;
        },
        []
      );
    } else {
      newOrderBy = [...orderBy, { sort, direction: 'ASC' }];
    }
    setOrderBy(newOrderBy);

    setFilters({
      assignedToMe: false,
      completed: 'INCOMPLETE',
      queues: [queue.toUpperCase()],
      orderBy: newOrderBy,
    });
  };

  useEffect(() => {
    if (
      /data_ops_review|data_ops_configuration|field|engineering/.test(queue)
    ) {
      const filters = {
        assignedToMe: false,
        completed: 'INCOMPLETE',
        queues: [queue.toUpperCase()],
      };
      if (prevQueue.current === queue) {
        filters.orderBy = orderBy;
      } else {
        // reset orderBy to init when switch to another queue
        prevQueue.current = queue;
        filters.orderBy = INIT_ORDER_BY;
        setOrderBy([...INIT_ORDER_BY]);
      }
      setFilters(filters);
    }
  }, [queue]);

  useEffect(() => {
    if (filters) {
      setLoadingMore(false);
      return props.relay.refetchConnection(
        FETCH_COUNTS,
        () => {
          setFilters(null);
        },
        {
          filters,
        }
      ).dispose;
    }
  }, [filters]);

  const switchTab = (tab) => {
    if (!filters) {
      navigate(`/report_config_queues/${tab}`, { replace: true });
    }
  };

  const loadMore = () => {
    if (!props.relay.hasMore() || props.relay.isLoading()) {
      return;
    }
    setLoadingMore(true);
    props.relay.loadMore(LOAD_MORE_COUNTS, (error) => {
      setLoadingMore(false);
      if (error) {
        console.log(error);
      }
    });
  };

  const needLoadMore = ({ clientHeight, scrollHeight, scrollTop }) => {
    if (scrollHeight - clientHeight - scrollTop < LOAD_MORE_THRESHOLD) {
      loadMore();
    }
  };

  return (
    <div
      className="ReportConfigQueues"
      data-testid="ReportConfigQueues__container"
    >
      <ReportConfigQueuesToolbar viewer={viewer} />
      <div className="ReportConfigQueues--main flex align-stretch">
        <div className="ReportConfigQueues--sidebar">
          <ReportConfigQueuesTabs tab={queue} switchTab={switchTab} me={me} />
        </div>
        {/data_ops_review|data_ops_configuration|field|engineering/.test(
          queue
        ) ? (
          QUEUE_COMP_MAP[queue]({
            me,
            configurationTasks,
            loading: !!filters,
            orderBy,
            toggleOrderBy,
            needLoadMore,
            loadingMore: loadingMore,
            taskId,
            setTaskId,
          })
        ) : queue === 'user' && me.adminRole === 'DATA_OPERATIONS_MANAGER' ? (
          <UserManagement />
        ) : null}
      </div>
    </div>
  );
}

const PaginatedReportConfigQueues = createPaginationContainer(
  ReportConfigQueues,
  {
    viewer: graphql`
      fragment ReportConfigQueues_viewer on Viewer
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 100 }
        cursor: { type: "String" }
        filters: { type: "ConfigurationTasksFilter!" }
      ) {
        me {
          adminRole
        }
        ...ReportConfigQueuesToolbar_viewer
        configurationTasks(first: $count, after: $cursor, filters: $filters)
          @connection(
            key: "ReportConfigQueues_configurationTasks"
            filters: []
          ) {
          edges {
            node {
              id
              dbId
              queue
              store {
                dbId
                firstEngagePingAt
                storeChain {
                  dbId
                }
              }
              dataOperator {
                user {
                  email
                }
                assignedAt
              }
              engineer {
                user {
                  email
                }
                assignedAt
              }
              fieldMember {
                user {
                  email
                }
                assignedAt
              }
              updatedBy {
                email
              }
              createdAt
              updatedAt
              exception {
                name
                queue
              }
            }
          }
          totalCount
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.configurationTasks;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      const { filters } = fragmentVariables;

      return {
        count,
        cursor,
        filters,
      };
    },
    query: graphql`
      query ReportConfigQueuesPaginationQuery(
        $count: Int
        $cursor: String
        $filters: ConfigurationTasksFilter!
      ) {
        viewer {
          ...ReportConfigQueues_viewer
            @arguments(count: $count, cursor: $cursor, filters: $filters)
        }
      }
    `,
  }
);

const ReportConfigQueuesQueryRenderer = createQueryRenderer(
  PaginatedReportConfigQueues,
  graphql`
    query ReportConfigQueuesQuery($filters: ConfigurationTasksFilter!) {
      viewer {
        ...ReportConfigQueues_viewer @arguments(filters: $filters)
      }
    }
  `,
  {
    filters: {
      assignedToMe: false,
      completed: 'INCOMPLETE',
      queues: [],
    },
  }
);

export default function ReportConfigQueuesSwitch() {
  return (
    <Routes>
      <Route path={':queue'} element={<ReportConfigQueuesQueryRenderer />} />
      <Route
        index
        element={<Navigate to={'/report_config_queues/data_ops_review'} />}
      />
    </Routes>
  );
}
