import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';

import environment from 'Relay/environment';

const mutation = graphql`
  mutation GeneratePreviewReportInReportConfigMutation(
    $input: GeneratePreviewReportInput!
    $id: ID!
  ) {
    generatePreviewReport(input: $input) {
      viewer {
        configurationTask(id: $id) {
          reportAccountStore {
            lastPreviewReport {
              dbId
              beginAt
            }
          }
        }
      }
    }
  }
`;

function GenerateReports(variables, onCompleted, onError) {
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted,
    onError
  });
}

export default GenerateReports;
