import { Alert } from 'disco-ui';

import DelayRender from 'Components/DelayRender';

export default function EmptyList() {
  // delay render because of react-spring effect
  return (
    <DelayRender delay={200}>
      <Alert className="m-3" color="warning" size="small">
        The list is empty
      </Alert>
    </DelayRender>
  );
}
