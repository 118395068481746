/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import cn from 'classnames';
import { DateTime } from 'luxon';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import ConfigLocalStorage from 'Services/ConfigLocalStorage';

// Relay
import graphql from 'babel-plugin-relay/macro';
import createQueryRenderer from 'Relay/createQueryRenderer';
import GeneratePreviewReportInReportConfig from 'Mutations/GeneratePreviewReportInReportConfig';

// Components
import { Button, Card } from 'disco-ui';
import JsonCodeMirror from '../JsonCodeMirror';
import ReportConfigEditorPreview from '../ReportConfigEditorPreview';
import ConfigurationTaskComments from 'Components/ConfigurationTaskComments';

function ReportConfigEditorContent({
  id,
  dbId,
  viewer,
  assignTo,
  assigning,
  completeTask,
  completing,
  setToast,
}) {
  const { me, configurationTask } = viewer;

  const { reportAccountStore, queue, completion } = configurationTask || {};

  const {
    configuration = '',
    lastPreviewReport,
    dbId: reportAccountStoreId,
  } = reportAccountStore || {};

  // JsonCodeMirror
  const [initConfig, setInitConfig] = useState('');
  const [config, setConfig] = useState(null);
  const [lastSaved, setlastSaved] = useState(null);

  useEffect(() => {
    let cfg = ConfigLocalStorage.get(dbId);
    if (cfg) {
      const ts = ConfigLocalStorage.getTs(dbId);
      setlastSaved(ts);
      setInitConfig(cfg);
    } else {
      setlastSaved(null);
      setInitConfig(configuration);
    }
  }, [dbId]);

  useEffect(() => {
    let timeOut;
    if (config !== null) {
      timeOut = setTimeout(() => {
        ConfigLocalStorage.set(dbId, config);
        const ts = ConfigLocalStorage.getTs(dbId);
        setlastSaved(ts);
      }, [500]);
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [config]);

  function discardLocalConfig() {
    ConfigLocalStorage.remove(dbId);
    setlastSaved(null);
    setInitConfig(configuration);
  }

  const isReadOnly =
    completion === 'COMPLETE' ||
    (/DATA/i.test(me.adminRole) && /FIELD|ENGINEERING/i.test(queue));

  // Genereate Preview Report
  const [generating, setGenerating] = useState(null); // {reportAccountStoreId, configuration}
  const [previewReport, setPreviewReport] = useState(null);

  function generatePreview() {
    let localCfg = ConfigLocalStorage.get(dbId);
    let cfg = config ? config : localCfg ? localCfg : configuration;

    try {
      JSON.parse(cfg);
    } catch (e) {
      setToast({
        type: 'error',
        content: 'JSON syntax error. Please check the configuration syntax!',
      });
      return;
    }

    const input = {
      reportAccountStoreId,
      configuration: cfg,
    };

    setGenerating(input);
  }

  useEffect(() => {
    if (generating) {
      const input = generating;

      GeneratePreviewReportInReportConfig(
        {
          input,
          id, // from props, this is task dbId
        },
        (res) => {
          let report = get(
            res,
            'generatePreviewReport.viewer.configurationTask.reportAccountStore.lastPreviewReport'
          );
          setPreviewReport(report);
          setGenerating(null);
        },
        (err) => {
          const { message, state } = err[0] || {};

          const stateErrors = state
            ? reduce(
                state,
                (acc, errorMessages, key) => {
                  if (key) {
                    // only show the errorMessages that come with key
                    return acc.concat(errorMessages);
                  } else {
                    return acc;
                  }
                },
                []
              )
            : [];

          const content = (
            <>
              <p>{message}</p>
              {stateErrors.map((msg, idx) => (
                <p className="mt-1 text-sm" key={idx}>
                  {msg}
                </p>
              ))}
            </>
          );

          setToast({
            type: 'error',
            content,
          });
          setGenerating(null);
        }
      );
    }
  }, [generating]);

  function closePreview() {
    setPreviewReport(null);
  }

  // Done and Next
  function doneAndNext() {
    let localCfg = ConfigLocalStorage.get(dbId);
    let cfg = config ? config : localCfg ? localCfg : configuration;

    try {
      JSON.parse(cfg);
    } catch (e) {
      setToast({
        type: 'error',
        content: 'JSON syntax error. Please check the configuration syntax!',
      });
      return;
    }

    completeTask(dbId, cfg, queue);
  }

  return (
    <>
      <div className={cn('flex-1 ReportConfigEditorContent')}>
        <JsonCodeMirror
          defaultValue={initConfig}
          onChange={setConfig}
          setToast={setToast}
          readOnly={isReadOnly}
        />
        {!isReadOnly && (
          <div className="flex justify-between items-center px-3 mt-3">
            <div>
              {lastSaved && (
                <div className="text-sm">
                  <span className="text-primary mr-2">
                    Last saved local config:{' '}
                    {DateTime.fromMillis(lastSaved / 1).toFormat(
                      'yyyy-MM-dd HH:mm:ss'
                    )}
                  </span>
                  <span className="text-link" onClick={discardLocalConfig}>
                    Discard
                  </span>
                </div>
              )}
            </div>
            <div>
              {lastPreviewReport && (
                <Button
                  size="small"
                  color="white"
                  className="mr-2"
                  onClick={() => {
                    setPreviewReport(lastPreviewReport);
                  }}
                >
                  View Last Report
                </Button>
              )}
              <Button
                size="small"
                color="primary"
                onClick={generatePreview}
                disabled={generating}
                activated={generating}
              >
                {generating ? 'Generating Report...' : 'Generate Report & View'}
              </Button>
            </div>
          </div>
        )}
        <div className="px-3 mt-3">
          <Card className="p-3">
            <h4 className="text mb-3">Comments</h4>
            <ConfigurationTaskComments configurationTask={configurationTask} />
          </Card>
        </div>
        {!isReadOnly && (
          <div className="flex justify-between aligin-center p-3">
            {me.adminRole === 'ENGINEER' ? (
              <Button
                size="small"
                color="white"
                className="mr-2"
                disabled={assigning || completing}
                activated={
                  assigning && assigning.queue === 'DATA_OPS_CONFIGURATION'
                }
                onClick={() => assignTo(dbId, 'DATA_OPS_CONFIGURATION', queue)}
              >
                Assign to Data Ops
              </Button>
            ) : (
              <div className="flex aligin-center">
                <Button
                  size="small"
                  color="white"
                  className="mr-2"
                  disabled={assigning || completing}
                  activated={assigning && assigning.queue === 'FIELD'}
                  onClick={() => assignTo(dbId, 'FIELD', queue)}
                >
                  Assign to Field
                </Button>
                <Button
                  size="small"
                  color="white"
                  disabled={assigning || completing}
                  activated={assigning && assigning.queue === 'ENGINEERING'}
                  onClick={() => assignTo(dbId, 'ENGINEERING', queue)}
                >
                  Assign to Engineer
                </Button>
              </div>
            )}

            <div>
              <Button
                size="small"
                color="primary"
                disabled={assigning || completing}
                activated={completing}
                onClick={doneAndNext}
              >
                Done & Next
              </Button>
            </div>
          </div>
        )}
      </div>
      {previewReport && (
        <ReportConfigEditorPreview
          closePreview={closePreview}
          previewReport={previewReport}
        />
      )}
    </>
  );
}

export default createQueryRenderer(
  ReportConfigEditorContent,
  graphql`
    query ReportConfigEditorContentQuery($id: ID!) {
      viewer {
        me {
          adminRole
        }
        configurationTask(id: $id) {
          id
          dbId
          queue
          completion
          reportAccountStore {
            dbId
            configuration
            lastPreviewReport {
              dbId
              beginAt
            }
          }
          ...ConfigurationTaskComments_configurationTask
        }
      }
    }
  `,
  ({ id }) => {
    return {
      id,
    };
  }
);
