import { createContext, useContext, useReducer } from 'react';
import { combineReducers, persistReducer } from 'Reducers/helpers';

import TokenReducer from 'Reducers/Token';

const AppReducer = combineReducers({
  token: persistReducer({ key: 'token', storage: localStorage })(TokenReducer)
});

const initState = AppReducer();

export const AppReducerContext = createContext();

// for provider
export function useAppReducer() {
  return useReducer(AppReducer, initState);
}

// for consumer
export default function useAppReducerContext() {
  return useContext(AppReducerContext);
}
