/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Toggle } from 'disco-ui';

// Relay
import graphql from 'babel-plugin-relay/macro';
import createQueryRenderer from 'Relay/createQueryRenderer';
import ToggleDataOperatorActive from 'Mutations/ToggleDataOperatorActive';

import { getEmailHandleFromUser } from 'Services/formatter';

function UserManagementHead() {
  return (
    <div className="ReportConfigQueuesList--head">
      <div className="flex items-center px-3 py-2 text-sm">
        <div className="flex-160 px-1 flex items-center">User</div>
        <div className="flex-100 px-1 flex justify-center items-center">
          Review
        </div>
        <div className="flex-100 px-1 flex justify-center items-center">
          Configure
        </div>
        <div className="flex-100 px-1 flex justify-center items-center">
          Field
        </div>
        <div className="flex-100 px-1 flex justify-center items-center">
          Engineer
        </div>
        <div className="flex-120 px-1 flex justify-center items-center">
          Activate
        </div>
      </div>
    </div>
  );
}

function UserManagementItem({ user }) {
  let { dbId, workQueueActive, workQueueMetrics } = user;
  const handle = getEmailHandleFromUser(user);
  const [active, setActive] = useState(workQueueActive);

  try {
    workQueueMetrics = JSON.parse(workQueueMetrics);
  } catch (err) {
    workQueueMetrics = {};
  }

  useEffect(() => {
    if (active !== workQueueActive) {
      setActive(workQueueActive);
    }
  }, [workQueueActive]);

  useEffect(() => {
    if (active !== workQueueActive) {
      ToggleDataOperatorActive(
        { user_id: dbId, workQueueActive: active },
        null,
        (err) => {
          // revert back
          setActive(!active);
        }
      );
    }
  }, [active]);

  return (
    <div className="ReportConfigQueuesList--row">
      <div className="flex items-center px-3 py-2 text-sm">
        <div className="flex-160 px-1 flex items-center">{handle}</div>
        <div className="flex-100 px-1 flex justify-center  items-center">
          {workQueueMetrics['data_ops_review']
            ? workQueueMetrics['data_ops_review']
            : '-'}
        </div>
        <div className="flex-100 px-1 flex justify-center items-center">
          {workQueueMetrics['data_ops_configuration']
            ? workQueueMetrics['data_ops_configuration']
            : '-'}
        </div>
        <div className="flex-100 px-1 flex justify-center items-center">
          {workQueueMetrics['field'] ? workQueueMetrics['field'] : '-'}
        </div>
        <div className="flex-100 px-1 flex justify-center items-center">
          {workQueueMetrics['engineering']
            ? workQueueMetrics['engineering']
            : '-'}
        </div>
        <div className="flex-120 px-1 flex justify-center items-center">
          <Toggle type="switch" checked={active} onChange={setActive} />
        </div>
      </div>
    </div>
  );
}

function UserManagement({ viewer }) {
  const { users } = viewer;
  return (
    <div className="ReportConfigQueuesList flex-1 flex col">
      <UserManagementHead />
      {users && users.edges.length ? (
        <div className="ReportConfigQueuesList--result">
          {users.edges.map(({ node }) => (
            <UserManagementItem key={node.dbId} user={node} />
          ))}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default createQueryRenderer(
  UserManagement,
  graphql`
    query UserManagementQuery($count: Int, $filters: UsersFilter!) {
      viewer {
        users(first: $count, filters: $filters) {
          edges {
            node {
              dbId
              email
              adminRole
              workQueueActive
              workQueueMetrics
            }
          }
        }
      }
    }
  `,
  {
    count: 100,
    filters: {
      adminRole: ['DATA_OPERATIONS_MANAGER', 'DATA_OPERATOR'],
    },
  }
);
